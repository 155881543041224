import { render, staticRenderFns } from "./import-case-common.vue?vue&type=template&id=a0f2bd16&scoped=true"
import script from "./import-case-common.vue?vue&type=script&lang=ts"
export * from "./import-case-common.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0f2bd16",
  null
  
)

export default component.exports